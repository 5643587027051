export default {
  primary: {
    padding: '0.75rem 2rem',
    color: 'white',

    transition: 'all ease-in-out 0.7s',
    backgroundSize: '204% 100%',
    backgroundColor: 'tertiary',
    backgroundImage: 'linear-gradient(to right,rgba(0,0,0,0) 50%,black 50%)',
    border: 'none',
    borderRadius: '100px',
    fontFamily: 'heading',
    fontWeight: 'bold',
    ':hover': {
      backgroundColor: 'transparent',
      backgroundPosition: '-98% 0%',
      color: 'light'
    }
  },
  secondary: {
    color: 'light',
    borderRadius: '0px',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    fontFamily: 'body',
    fontWeight: 'bold',
    display: 'flex',
    border: 'none',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: ['0.9rem', '', '1rem'],
    padding: '1rem',
    backgroundColor: 'black',
    transition: 'all ease-in-out 0.5s',
    ':hover': {
      backgroundColor: 'secondary',
      borderColor: 'white'
    }
  }
}
