export default {
  heading: 'cooper-black-std', //'Barlow Condensed, sans-serif',
  subheading: 'Tilt Neon, sans-serif',
  body: 'Ovo, sans-serif',
  body2: 'Open Sans, sans-serif',
  monospace: 'Menlo, monospace',
  display: 'Playfair Display, sans-serif',
  googleFonts: [
    'Ovo',
    'Open Sans:100,200,300,400,500,600,700,800,900',
    'Comfortaa',
    'Tilt Neon',
    'Playfair Display:400,500,600,700'
  ]
  // customFamilies: ['Billy', 'Blowhole', 'Supersonic Rocketship'],
  // customUrls: [
  //   'https://www.gonation.biz/fonts/billy/billy.css',
  //   'https://www.gonation.biz/fonts/blowhole/blowhole.css',
  //   'https://www.gonation.biz/fonts/supersonic-rocketship/supersonic-rocketship.css'
  // ]
}
