export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.container': {
      padding: ['1rem', '1.5rem'],
      position: 'absolute',
      backgroundColor: 'transparent',
      top: '0rem',
      '.smallNavMenu': {
        '.navItem': {
          color: 'white',
          margin: '0rem 0.5rem',
          a: {
            fontSize: '1rem',
            ':hover': {
              textDecoration: 'underline',
              color: 'primary'
            }
          }
        },
        '.order-online': {
          display: ['inherit', '', '', '', 'none']
        },
        '.react-reveal': {
          opacity: '1'
        }
      }
    },
    '.containerScrolled': {
      border: 'none',
      padding: '0.5rem 1rem',
      backgroundColor: 'background',
      position: 'fixed',
      right: '0rem',
      top: '0rem',
      boxShadow: '2px 2px 10px black',
      left: 'unset',
      '.smallNavMenu': {
        '.order-online': {
          display: ['inherit', '', '', '', 'none']
        },
        '.navItem': {
          color: 'text',
          a: {
            fontSize: '1rem',
            ':hover': {
              textDecoration: 'underline',
              color: 'primary'
            }
          }
        },
        '.react-reveal': {
          opacity: '1'
        }
      }
    },

    // top level nav styles

    '.logoLocationContainer': {
      padding: '0rem',
      position: 'static',
      margin: '0rem auto 0rem 0rem'
    },
    '.logo': {
      a: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      },
      img: {
        maxHeight: ['100px', '120px', '110px', '120px', '120px'],
        filter: 'unset'
      }
    },
    '.logoScrolled': {
      img: {
        maxHeight: ['60px', '70px', '70px', '70px', '70px'],
        filter: 'unset'
      }
    },

    // hamburger
    '.hamburger': {
      borderRadius: '100px',
      padding: '0.75rem',
      borderColor: 'white',
      backgroundColor: 'primary',
      display: ['', '', ''],
      order: '5',
      margin: '0rem',
      display: ['', '', '', 'none'],
      '> div': {
        backgroundColor: 'white'
      }
    },

    '.hamburgerOpen': {
      borderColor: 'white',
      borderRadius: '100px',
      padding: '0.5rem',
      borderColor: 'primary',
      backgroundColor: 'white',
      display: ['', '', ''],
      order: '5',
      margin: '0rem',
      '> div': {
        backgroundColor: 'text',
        width: '80%',
        height: '2px'
      }
    },

    '.socialIconsContainerDesktop': {
      margin: ['0rem 1rem 0rem auto', '', '', '0rem'],
      padding: '0rem',
      display: 'flex',
      order: '4',
      a: {
        padding: '0rem 0.25rem',
        svg: {
          height: '35px',
          width: '35px',
          padding: '0.25rem',
          borderRadius: '10px',
          backgroundColor: 'rgba(255, 255, 255, 0.35)',
          path: {
            fill: 'black'
          }
        }
      }
    },
    '.order-now': {
      variant: 'buttons.primary',
      padding: '0.75rem 1.25rem',
      a: {
        color: 'white',
        ':hover': {
          color: 'white !important'
        }
      }
    },
    '.smallNavMenu': {
      order: '3',
      '.navItemDropdownMenu': {
        backgroundColor: 'black'
      },
      '.navItem': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
      }
    },
    '.navItem': {
      a: {
        fontFamily: 'heading'
      }
    },

    // nav menu styles
    '.navMenu': {
      width: ['85%', '60%', '50%', '40%'],
      left: '-100vw',
      top: '0rem',
      right: 'unset',
      transform: 'unset',
      position: 'fixed',
      color: 'white',
      backgroundColor: 'transparent',
      height: '100vh',
      alignItems: ['flex-start', 'flex-start', 'flex-start', 'flex-start'],
      '.navMenuLogo': {
        display: 'none'
      },
      '.navItem': {
        textAlign: 'left',
        a: {
          fontSize: '1.5rem',
          letterSpacing: '0px',
          fontWeight: 'bold',

          ':hover': {
            color: 'primary'
          }
        }
      }
    },
    '.navMenuOpen': {
      '.navMenuLogo': {
        display: 'none'
      },
      '::before': {
        content: "'Navigation'",
        padding: '0.5rem',
        mb: '1.5rem',
        fontWeight: 'bold',
        fontSize: '1.25rem',
        marginLeft: ['1rem'],

        borderBottom: '1px solid',
        textTransform: 'uppercase',
        fontFamily: 'body2'
      },
      color: 'black',
      backgroundColor: 'white',
      padding: ['', '', '', '2rem'],
      width: ['100%', '100%', '100%', '100%'],
      alignItems: ['flex-start', 'flex-start', 'flex-start', 'flex-start'],
      transform: 'unset',
      position: 'fixed',
      left: '0rem',
      right: 'unset',
      '.navItem': {
        textAlign: 'left',
        paddingLeft: ['1.25rem'],
        marginBottom: '1rem',
        a: {
          fontSize: ['1.5rem', '1.75rem', '2.5rem', '3rem'],
          letterSpacing: '0px',
          fontWeight: 'bold',
          color: 'inherit',
          ':hover': {
            color: 'primary'
          }
        }
      },
      '.seperatorLine': {
        display: 'none'
      },

      '.phoneSocialContainer': {
        mt: '1.5rem',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        '.smallNavHeading': {
          textAlign: 'left',
          fontFamily: 'heading'
        },

        '.phoneContainer, .socialIconsContainer': {
          p: '1rem',
          '.socialContainer': {
            p: '0rem'
          },
          justifyContent: 'flex-start',
          a: {
            justifyContent: 'flex-start',
            fontSize: ['1rem']
          }
        }
      },

      '.navItemDropdown': {
        display: 'flex',
        flexDirection: 'column'
      },

      '.navItemDropdownMenu': {
        position: 'static',
        background: 'transparent',
        textShadow: 'unset',
        '.navItem': {
          ml: '1rem'
        }
      }
    },

    '.navBlock': {
      right: 'unset',
      top: '200vh'
    }
  },

  footer: {
    zIndex: '1000',
    position: 'relative',
    borderTop: '1px solid black',
    backgroundColor: 'white',
    color: 'black',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    padding: ['0.5rem 0.5rem 6rem', '', '0.5rem 3rem'],
    '.image': {
      // filter: 'brightness(0) invert(1)',
      display: 'none'
    },
    '.multiButtonContainer': {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0rem 0rem 1rem',
      a: {
        margin: ['0rem', '', '', '0.5rem'],
        width: 'fit-content',
        fontFamily: 'heading',
        color: 'inherit'
      }
    },
    '.contactDetails-container': {
      order: '2',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
      mb: '0.5rem'
    },
    '.copyright': {
      order: '3',
      width: '50%',
      textAlign: 'center'
    },
    '.socialIconsContainer': {
      order: '2',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '0.5rem',
      a: {
        svg: {
          width: '40px',
          height: '40px',
          padding: '0.25rem',

          ':hover': {
            backgroundColor: 'grey'
          }
        },
        margin: '0.5rem'
      }
    },
    '.gonationLogo': {
      order: '4',
      width: ['100%', '', '50%'],
      filter: 'unset'
    },

    '.address': {
      '*': {
        textAlign: 'center'
      }
    }
  },

  ctaWidget: {
    zIndex: '9999',
    a: {
      fontFamily: 'heading'
    }
  },

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    fontSize: ['2rem', '2.25rem', '2.5rem', '2.75rem', '3rem'],
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'inherit',
    marginBottom: '1.5rem',
    textTransform: 'uppercase',
    fontFamily: 'heading'
  },
  subtitle: {
    width: 'fit-content',
    textAlign: 'inherit',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: ['1.5rem', '1.75rem', '2rem', '2rem'],
    marginBottom: '1.5rem',
    border: 'none',
    color: 'inherit',
    fontFamily: 'subheading',
    fontWeight: 'bold'
  },
  text: {
    p: {
      color: 'inherit',
      lineHeight: '2',
      fontSize: 'inherit',
      textAlign: 'center'
    }
  },

  sideBySide1: {
    backgroundColor: 'transparent',
    padding: ['2rem 1rem', '', '3rem 1rem 4rem'],
    margin: ['', '', '0rem 0rem 0rem'],
    alignItems: ['center', 'center', 'center', 'center'],
    '.lazyload-wrapper': {
      width: ['100%', '', '65%', '55%'],
      minHeight: ['', '', '50vh'],
      maxHeight: ['', '', '70vh'],
      justifyContent: 'flex-start',
      my: '2rem',
      img: {
        border: 'double 16px',
        borderColor: 'black'
      }
    },

    '.content': {
      border: '15px double',
      borderColor: 'black',
      width: ['100%', '', '45%', '40%'],
      position: 'relative',
      alignItems: 'flex-start',
      margin: ['1rem 0rem', '', '0rem'],
      padding: ['2rem 1rem', '2rem', '3rem', '3rem', '3rem'],
      backgroundColor: 'white',
      height: 'fit-content',
      color: 'text',
      position: 'relative',
      alignSelf: 'center',
      left: ['', '', '4rem']
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text',
      mb: '1.5rem',
      p: {
        textAlign: 'left'
      }
    },
    a: {
      order: '5'
    },
    '.ctaButton': {
      variant: 'buttons.primary',
      marginBottom: '0rem'
    },
    '.secondaryCtaButton': {
      variant: 'buttons.primary'
    }
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1',
    paddingLeft: ['', '', '4rem'],
    py: '3rem',
    'div.content': {
      left: ['', '', '-4rem']
    }
  },

  centerBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '90vh',
    color: 'white',
    textAlign: 'center',
    padding: ['6rem 1rem', '8rem 1rem'],
    '.section': {
      maxWidth: 'unset',
      width: '95%',
      maxWidth: '1200px'
    },
    '.title': {
      variant: 'customVariants.title',
      color: 'white',
      fontSize: ['1.7rem', '1.75rem', '2.5rem', '2.75rem', '3rem', '3.5rem', '3.75rem'],
      order: '1'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      color: 'white',
      fontSize: ['2rem', '2.5rem', '3rem', '3.5rem'],
      order: '2'
    },

    '.text': {
      variant: 'customVariants.text',
      color: 'white',
      order: '3',
      mb: '2rem',
      maxWidth: '600px',
      p: {
        lineHeight: 1.5,
        fontSize: '1.25rem',
        color: 'white'
      }
    },
    '.linksContainer': {
      order: '4',
      '.ctaButton': {
        variant: 'buttons.primary',
        letterSpacing: '0px',
        textTransform: 'capitalize'
      },
      '.secondaryCtaButton': {
        variant: 'buttons.primary',
        letterSpacing: '0px',
        textTransform: 'capitalize'
      }
    }
  },

  centerBlock2: {
    variant: 'customVariants.centerBlock'
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageTag1: {
    '.hero_content_container': {
      left: ['50%'],
      top: '57.5%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
      alignItems: 'center',
      width: '95%',
      maxWidth: '100%',
      marginLeft: '0rem'
    },

    '.title': {
      variant: 'customVariants.title',
      color: 'white',
      fontSize: ['2rem', '2.25rem', '3rem', '3rem'],
      textAlign: 'center',
      fontWeight: 'bold',
      maxWidth: 800,
      margin: 'auto'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      display: 'block',
      color: 'white',
      fontSize: ['2rem', '2rem', '2.5rem', '3rem'],
      width: '100%'
    },
    '.text': {
      variant: 'customVariants.text',
      color: 'white',
      '*': {
        lineHeight: '1'
      }
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    },
    '.secondaryCtaButton': {
      variant: 'buttons.primary'
    },
    '.slick-slide': {
      img: {
        filter: 'brightness(0.8)'
      }
    },

    '.slick-arrow': {
      height: '60px',
      width: '60px',
      color: 'white !important',
      filter: 'drop-shadow(0px 0px 6px rgba(0,0,0,0.8))',
      bottom: '-1rem',
      top: 'unset',
      left: '0rem',
      display: 'none !important'
    },

    '.slick-prev': {
      left: '1rem'
    },

    '.slick-next': {
      left: '3rem'
    },

    '.slick-dots': {
      li: {
        button: {
          color: 'orange',
          '::before': {
            color: 'rgb(0,0,0,.8)',
            fontSize: '1.5rem'
          }
        }
      }
    },
    '.slick-dots li.slick-active button:before': {
      color: 'primary'
    }
  },

  homepageHeroShout: {
    display: 'none'
  },

  homepageTag2: {
    variant: 'customVariants.sideBySide1'
  },

  homepageTag3: {
    variant: 'customVariants.centerBlock'
  },

  homepageTag4: {
    variant: 'customVariants.sideBySide2',
    paddingBottom: '0rem',
    mb: '-5vh',
    backgroundColor: 'white'
  },

  homepageTag5: {
    backgroundColor: 'white',
    '.section': {
      minHeight: ['300px', '', '450px']
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['3rem', '3.5rem', '4.5rem', '5rem', '6rem']
    },
    '.subtitle': {
      variant: 'customVariants.title',
      fontSize: ['2.5rem', '2.75rem', '3.25rem', '4rem', '4.5rem']
    },
    '.imageText': {
      left: ['5vw', '', '10vw'],
      right: ['5vw', '', '10vw']
    },
    '.text': {
      variant: 'customVariants.text'
    }
  },
  homepageTagDays: {
    backgroundColor: 'white',
    '.section': {
      minHeight: ['300px', '', '450px']
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['3rem', '3.5rem', '4.5rem', '5rem', '6rem']
    },
    '.subtitle': {
      variant: 'customVariants.title',
      fontSize: ['2.5rem', '2.75rem', '3.25rem', '4rem', '4.5rem']
    },
    '.imageText': {
      left: ['5vw', '', '10vw'],
      right: ['5vw', '', '10vw']
    },
    '.text': {
      variant: 'customVariants.text'
    },
    img: {
      display: 'none'
    }
  },

  homepageTag6: {
    variant: 'customVariants.sideBySide1'
  },

  homepageTestimonials: {
    padding: ['2rem 1rem', '2rem', '4rem 3rem'],
    maxWidth: 'unset',
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    '.innerMenuContainer': {
      border: 'double 5px',
      borderColor: 'primary',
      padding: ['', '2rem 1rem'],
      backgroundColor: 'background'
    },
    '.menuSectionTitle': {
      margin: '1rem auto',
      fontSize: ['2rem', '', '', '3rem'],
      textTransform: 'capitalize',
      fontWeight: '300',
      textAlign: 'center',
      paddingBottom: '0rem',
      width: 'fit-content',
      color: 'primary',
      borderBottom: '3px solid'
    },

    '.menuSectionDescription': {
      textAlign: 'left',
      fontSize: ['1.25rem', '1.5rem'],
      opacity: '0.6',
      textAlign: 'center',
      margin: ['0rem 0rem 1rem', '', ''],
      color: 'secondary',
      fontFamily: 'display',
      maxHeight: 'unset'
    },
    '.menuItemContainer': {
      height: '300px',
      display: 'flex',
      alignItems: 'center',
      overflowY: 'scroll',
      MsOverflowStyle: 'none',
      scrollbarWidth: 'none',
      '::-webkit-scrollbar': {
        display: 'none'
      }
    },

    '.menuItemInnerContainer, .menuItemContentContainer ': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },

    '.menuItemNameContainer': {
      order: '4',
      marginTop: '1rem'
    },
    '.menuItemName': {
      color: 'primary',
      fontSize: ['1.1rem', '', '1.4rem'],
      textAlign: 'center',
      lineHeight: '1.5',
      width: '100%',
      fontFamily: 'heading',
      padding: ['0rem', '', '0rem 1rem']
    },

    '.menuItemDescription': {
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '200px',
      overflowY: 'scroll',
      textAlign: 'center',
      fontSize: ['1.25rem', '1.5rem'],
      fontStyle: 'italic',
      MsOverflowStyle: 'none',
      scrollbarWidth: 'none',
      '::-webkit-scrollbar': {
        display: 'none'
      }
    },

    '.slick-slider': {
      maxWidth: '767px',
      margin: '0 auto',
      '.slick-arrow': {
        backgroundColor: 'white',
        padding: '0.5rem',
        height: '40px',
        width: '40px',
        color: 'primary'
      }
    }
  },

  homepageTag7: {
    variant: 'customVariants.centerBlock'
  },

  homepageShout: {
    backgroundColor: 'background',
    margin: '0rem',
    padding: '0rem',
    '.title': {
      border: ['', '', '', 'solid 14px'],
      borderColor: ['', '', '', 'primary'],
      fontSize: ['1.5rem', '', '2rem']
    },
    '.text': {
      fontFamily: 'Handy Casual Condensed',
      fontSize: ['2rem', '', '2.5rem'],
      order: ['4', '', '3'],
      fontFamily: 'display',
      fontWeight: 'normal',
      padding: ['1rem', '', '1.5rem 2.5rem'],
      backgroundColor: ['white', '', '', 'white'],
      border: ['', '', '', 'double 9px'],
      borderColor: ['', '', '', 'black']
    },
    '.date': {
      backgroundColor: 'black',
      fontSize: '0.8rem',
      fontWeight: 'bold',
      writingMode: ['', '', '', 'tb'],
      p: '1rem 0.5rem'
    },
    '.imageContainer': {
      order: ['', '', '4']
    },
    '.shoutCTAS': {
      border: 'solid 8px',
      borderColor: 'background'
    }
  },

  homepageInstagram: {
    backgroundColor: 'white',
    pt: '0.25rem',
    '.logoAndTextContainer ': {
      // margin: '3rem 0rem 2rem',
      display: 'none'
    },
    '.iFrameContainer': {
      width: ['100%', '100%', '100%']
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    backgroundImage: 'url(https://www.transparenttextures.com/patterns/bedge-grunge.png)',
    '.section': {
      maxWidth: '1000px',
      alignItems: 'flex-start',
      textAlign: 'left'
    },
    '.title': {
      variant: 'customVariants.title',
      borderBottom: '2px solid',
      borderColor: 'primary'
    },
    '.text': {
      fontSize: ['1.25rem', '1.5rem']
    }
  },

  bio: {
    variant: 'customVariants.sideBySide1',
    flexDirection: ['column', '', 'column', 'row'],
    height: 'unset',
    maxHeight: 'unset',
    marginTop: '0rem',
    backgroundColor: '#e9e9e2',
    padding: '5rem 2rem',
    'div.content': {
      ml: ['', '', '', '-1rem'],
      left: ['0rem', '0rem', '0rem', '3rem'],
      width: ['', '', '100%', '60%']
    },

    'div.lazyload-wrapper': {
      overflow: 'hidden',
      width: ['', '', '100%', '40%'],
      maxHeight: 'unset',
      img: {
        objectFit: 'contain',
        objectPosition: 'top'
      }
    }
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  menu: {
    backgroundColor: 'black',
    maxWidth: 'unset',
    p: ['0rem', '0rem'],
    '.innerMenuContainer': {
      backgroundColor: 'black',
      color: 'white',
      // maxWidth: '1200px',
      border: 'double 16px white'
    },
    '.variantsContainer': {
      justifyContent: 'flex-end',
      display: 'flex'
    },
    '.menuSectionTitle': {
      variant: 'customVariants.title',
      fontSize: ['1.5rem', '1.75rem', '2.5rem', '2.5rem', '2.5rem'],
      textAlign: 'left',
      justifyContent: 'flex-start',
      alignItems: 'center',
      margin: ['0 0 1rem 0', '', '1rem 0rem'],
      padding: ['0 .5rem', '', '1rem']
    },
    '.menuSectionDescription': {
      fontWeight: '200',
      display: 'flex',
      margin: '1rem 0rem',
      padding: '1rem',
      textAlign: 'left',
      maxWidth: ['100%', '75%']
    },
    '.tabsRow': {
      justifyContent: 'center'
    },
    '.menuTab': {
      backgroundColor: 'white',
      color: 'text',
      fontFamily: 'heading',
      fontSize: '1.5rem',
      fontWeight: 'bold',
      ':hover': {
        backgroundColor: 'primary',
        color: 'white'
      }
    },
    '.menuTabActive': {
      backgroundColor: 'primary',
      color: 'white'
    },

    '.menuItemName': {
      fontFamily: 'heading',
      marginBottom: ['0.25rem', '', '', ''],
      fontSize: ['1.25rem', '', '1.1rem']
    },
    '.menuItemDescription': {
      fontSize: ['1rem', '1.25rem', '.9rem']
    },

    '.lightboxText': {
      backgroundColor: 'black'
    },
    '.menuItemInnerContainer': {
      flexWrap: 'wrap'
    },
    '.menuItemImageContainer': {
      maxWidth: 'none',
      maxHeight: 200,
      marginBottom: '4px'
    },
    '.menuItemImg': {
      padding: '0rem',
      borderRadius: 8
    },
    '.menuItemContainerImgActive': {
      marginBottom: ['1rem', '', '', '1rem'],
      padding: ['0 .5rem', '', '', '0rem']
    },
    '.create-your-own-sandwich .menuItemsContainer, .create-your-own-cheese-sandwich .menuItemsContainer': {
      display: 'flex'
    },
    '@media (max-width: 767px)': {
      '.create-your-own-sandwich .menuItemContainerImgActive, .create-your-own-cheese-sandwich .menuItemContainerImgActive':
        {
          width: '50%'
        }
    },
    '.menuItemPrice': {
      color: 'white',
      fontWeight: 'bold',
      fontFamily: 'body2',
      minWidth: ['unset', '', '', '42px']
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {},

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.events-container': {
      maxWidth: 'unset'
    },
    '.no-events-container': {
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px'
        // filter: 'brightness(0) invert(1)',
      }
    },

    '.eventItemImage': {
      minHeight: 'unset',
      width: '25%'
      // height: '100%',
    },
    '.eventDays, .eventDate': {
      color: 'primary'
    },
    '.eventItemDescription': {
      maxHeight: '250px',
      overflowY: 'scroll',
      '::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '4',
    textAlign: 'left',
    position: 'relative',
    alignItems: 'flex-start',
    '> *': {
      zIndex: '1'
    },
    '::before': {
      content: ["''"],
      backgroundColor: ['rgb(0 0 0 / 70%)', '', 'rgb(0 0 0 / 70%)'],
      width: ['100%', '', '50%'],
      height: '100%',
      position: 'absolute',
      top: '0%',
      right: '0%',
      zIndex: '0'
    },
    '.title': {
      variant: 'text.title',
      order: 'unset',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem'],
      maxWidth: '500px',
      width: '100%'
    },
    '.subtitle': {
      variant: 'text.subtitle',
      maxWidth: '500px',
      width: '100%',
      opacity: '0.7',
      mb: '2rem'
    },
    '.text': {
      maxWidth: '500px',
      width: '100%',
      mb: '2rem'
    },
    backgroundPosition: 'center center',
    color: 'white',
    padding: ['3rem 1rem', '', '6rem 1rem'],
    pl: ['', '', '52.5%'],
    form: {
      maxWidth: '500px'
    },
    '.inputField, .textField ': {
      fontWeight: '300',
      border: 'none',
      borderBottom: '1px solid',
      borderColor: 'white',
      borderRadius: '0px',
      padding: '1.5rem 0.5rem',
      color: 'light',
      fontFamily: 'heading',
      '::placeholder': {
        color: 'white'
      }
    },
    textarea: {
      '::placeholder': {
        color: 'text',
        fontWeight: '300',
        fontFamily: 'heading'
      }
    },

    '.submitBtn': {
      variant: 'buttons.primary',

      height: 'unset',
      width: 'auto',
      marginTop: '1.5rem'
    }
  },

  locationMap: {
    flexWrap: 'wrap',
    order: '3',
    backgroundColor: 'white',
    '.content_container': {
      padding: '0rem'
    },
    h3: {
      variant: 'customVariants.title',
      justifyContent: 'center',
      display: 'flex',
      order: 'unset',
      width: '100%',
      backgroundColor: 'black',
      padding: '0.5rem ',
      textAlign: 'center',
      fontSize: ['1.75rem', '1.75rem', '1.75rem', '1.75rem', '1.75rem'],
      alignItems: 'center',
      color: 'white'
    },
    '.contactDetails-container': {
      padding: '0rem 1rem 3rem'
    },
    '.hours-section': {
      maxWidth: 'unset',
      padding: '0rem',
      '.hoursContainer': {
        padding: ['1rem', '2rem', '3rem 4rem', '3rem 5rem']
      }
    },
    '.dayContainer': {
      mb: '0.5rem',
      mx: 'auto',
      maxWidth: '300px'
    },
    '.dayofWeekText': {
      margin: '0 auto 0rem 0',
      borderBottom: '1px solid'
    },
    '.textContent': {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      display: 'flex'
    }
  },

  // ? ================
  // ? === Catering ===
  // ? ================

  catering1: {
    variant: 'customVariants.aboutSection'
  },

  cateringMenu: {
    variant: 'customVariants.menu',
    '.menuItemContainerImgActive': {
      m: ['0.25rem 0.5rem', '0.25rem 0.5rem']
    },
    '.menuItemInnerContainer': {
      mb: '0rem'
    },
    '.menuItemDescription': {
      color: 'white'
    },

    '.menuItemName': {
      fontFamily: 'heading',
      fontSize: '1rem',
      width: 'calc(100% - 60px)',
      display: 'flex',
      mb: '0rem'
    },
    '.menuItemContainerImgActive': {
      width: ['', '', '', 'calc(33.3% - (0.5rem * 2))']
    },
    '.itemVariantsContainer': {
      mt: '0rem'
    }
  }
}
