export default {
  text: 'black',
  primary: '#9d0400',
  secondary: 'black',
  tertiary: '#cd0f28',
  quaternary: '#fa5235',
  background: 'white',
  backgroundSecondary: '#fdfdfd',
  light: '#FFF',
  dark: '#black'
}
